  .btn-sq {
    width: 100px !important;
    height: 100px !important;
    margin-right: 6px;
  }
  
  .btn-lp-r{
    background-color: #F63F7A;
    color: #ffffff;
  }
  .btn-lp-b{
    background-color: #0099CC;
    color: #ffffff;
  }

  .btn-lp-y{
    background-color: #CCCC00;
    color: #ffffff;
  }

  .btn-lp-g{
    background-color: #99CC32;
    color: #ffffff;
  }

  